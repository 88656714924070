import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getItem<T>(key: string): T | undefined {
    const item = localStorage.getItem(key);
    if (item) {
      return this.safelyParseJSON(item);
    }
    return;
  }

  private safelyParseJSON<T>(json: string): T | undefined {
    try {
      return JSON.parse(json) as T;
    } catch (e) {
      return undefined;
    }
  }
}
