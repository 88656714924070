import { Asset } from '@longnecktech/splash-commons-fe';
import { Competitor } from './competitor';
import { QuestionMatch } from './question-match';
import { QuestionOption } from './question-option';
import { FormControl } from '@angular/forms';

export interface Question {
  uuid: string;
  index: number;
  eventStartTime: string;
  competitor1: Competitor;
  competitor2?: Competitor;
  background: Asset;
  questionText: string;
  shortQuestionText: string;
  options: QuestionOption[];
  cardIcon?: Asset;
  match: QuestionMatch;
  type: QuestionType;
  competitor1WinBettingLine?: BettingLine;
  competitor2WinBettingLine?: BettingLine;
  drawBettingLine?: BettingLine;
  points?: number; // for CS game
  competitor1Score?: number; // for CS game. exist only on the FE side
  competitor2Score?: number; // for CS game. exist only on the FE side
  hideQuestion: boolean;
}

export enum QuestionType {
  Options = 'OPTIONS',
  CorrectScore = 'CORRECT_SCORE',
}

export interface ScoreControls {
  competitor1Score: FormControl;
  competitor2Score: FormControl;
}

export interface BettingLine {
  uuid: string;
  createdTs: string;
  updatedTs: string;
  value: number;
  state: number;
  name: string;
  externalOddsId: number;
  externalMarketId: number; // temporary, to be reomved
}

export interface BettingLineWithMatch extends BettingLine {
  matchExternalId: string;
}
